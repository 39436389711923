@import url('https://fonts.googleapis.com/css2?family=Kaushan+Script&display=swap');
.capture-button {
    position: fixed;
    z-index: 999;
    bottom: 25px;
    left: calc(50% - 40px);
    width: 80px;
    height: 80px;
    border: 0;
    background-image: url("../images/camera_button@2x.png");
    background-repeat: no-repeat;
    background-size: 80px 80px;
    border-radius: 50%;
}

.christmas_bg {
    background-color: #ffffff;
    background-image: url("../images/christmas_bg.jpg");
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: auto 100%;
    overflow-x: hidden;
    video {
        width: 100%;
    }

    .raffel-ticket-no{
        font-size: 20px;
        display: inline-block;
        background-color: rgb(228,30,41);
        padding: 3px 15px;
        margin: 5px;
        color: #ffffff;
        border-radius: 20px;
    }
    .camera-logo{
        position: fixed;
        bottom: 10px;
        right: 10px;
    }
}

.welcome-text {
    font-family: 'Kaushan Script', cursive;
}