@import url('https://fonts.googleapis.com/css2?family=Kaushan+Script&display=swap');
.winning-message {
    width: 700px;
    height: 200px;
    background-color: #ffffff;
    position: fixed;
    bottom: 20px;
    left: calc(50% - 350px);
    border: 10px solid #ff0000;
    
    text-align: center;
    padding: 20px;
    font-size: 35px;
    flex-direction: column;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
    color: #ff0000;
    span{
        font-size: 80px;
        font-weight: bold;
        color: #ff0000;
    }
    .profile-picture {
        height: 100px;
        width: auto;
        position: fixed;
        margin-top: -120px;
        border: 5px solid #ff0000;
        border-radius: 20px 20px 0 0;
    }
}